import axios from 'axios';
import { config } from './config';

export interface LoginToken {
  /**
   * The access token
   */
  access_token: string;
  /**
   * Access token expires in X seconds
   */
  expires_in: number;
  /**
   * The refresh token, can be used to acquire a new access token. Refresh tokens do not                                expire and must thus be kept save!
   */
  refresh_token: string;
  /**
   * A list of the users roles
   */
  roles: Array<string>;
  /**
   * Type of the access token
   */
  token_type: string;
  /**
   * The users username
   */
  username: string;
}

export interface LoginTokenWithExpiry extends LoginToken {
  expires_at: string;
  rememberMe?: boolean;
}

export const getAccessTokenByRefreshToken = async ({ refreshToken }: { refreshToken: string }) => {
  const { data } = await axios.post<LoginToken>(
    `${process.env.NEXT_PUBLIC_APP_DOMAIN}/oauth/access_token`,
    {},
    {
      ...config,
      params: {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      },
    }
  );
  return data;
};
