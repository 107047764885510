import { Autocomplete, Box, Button, styled, TextField, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, RefObject, useCallback, useContext, useEffect, useState } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useDebouncedCallback } from 'use-debounce';
import { CalculatorState } from '@rentablo/cashback-calculator';
import { Fund, useFunds } from '../../api/useFunds';
import CheckmarkCircled from '../../public/icons/checkmarkCircled.svg';
import { CashbackCalculatorContext } from '../CashbackCalculatorProvider';

const AutocompleteWrapper = styled(Box)`
  display: flex;
  align-items: center;

  & .MuiAutocomplete-paper {
    background: white;

    margin-top: 5px;
    box-shadow: 0 2px 4px -1px rgba(30, 39, 50, 0.16);
    border: 1px solid ${({ theme }) => theme.palette.border?.light};
  }

  & .MuiAutocomplete-loading,
  & .MuiAutocomplete-noOptions {
    font-size: 14px;
  }

  & .MuiAutocomplete-option:hover {
    background-color: ${({ theme }) => theme.palette.background.neutralLight};
  }
`;

const StyledAutocomplete = styled(Autocomplete<Fund>)`
  margin-right: ${({ theme }) => theme.spacing(2)};
  width: 300px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: auto;
    flex-grow: 1;
  }

  & .MuiInputBase-root {
    height: 38px;
    padding: ${({ theme }) => theme.spacing(0, 1, 0, 3)} !important;
    font-size: 14px;
    letter-spacing: -0.01em;

    & .MuiAutocomplete-input {
      padding: 0;

      &::placeholder {
        opacity: 1;
        color: ${({ theme }) => theme.palette.text.secondary};
      }
    }
  }

  & .MuiAutocomplete-clearIndicator {
    height: 38px;
    width: 38px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const StyledButton = styled(Button)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    & .MuiButton-startIcon {
      display: none;
    }
  }
`;

export const ISINSearch = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const context = useContext(CashbackCalculatorContext);
  const [ref, setRef] = useState<RefObject<CalculatorState>>();
  useEffect(() => {
    setRef(context);
  }, [context]);

  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState<Fund | null>(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { data: { funds } = { funds: [] }, refetch: getFunds } = useFunds(inputValue);

  const onInputChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);

    if (e.target.value) {
      setLoading(true);
      setOpen(true);
      await loadFunds();
    } else {
      setLoading(false);
      setOpen(false);
    }
  }, []);

  const loadFunds = useDebouncedCallback(async () => {
    await getFunds();
    setLoading(false);
  }, 300);

  const addFund = useCallback(() => {
    if (selectedOption) {
      ref?.current?.addFund?.(selectedOption);

      setTimeout(() => {
        const calculatorRef = ref?.current?.calculatorRef?.current;
        if (calculatorRef) {
          const newFund = calculatorRef.querySelector(`#fund-input-${selectedOption.isin}`);
          newFund?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 100);
    }
  }, [selectedOption, ref]);

  return (
    <AutocompleteWrapper>
      <StyledAutocomplete
        fullWidth
        forcePopupIcon={false}
        disablePortal
        options={funds || []}
        value={selectedOption}
        loading={loading}
        open={open}
        blurOnSelect
        onClose={() => setOpen(false)}
        filterOptions={(options) => options}
        onChange={(e, option) => {
          setSelectedOption(option);
          setOpen(false);
        }}
        getOptionLabel={(option) => option.name || ''}
        renderOption={(props, option, { inputValue }) => {
          const matches = match(option?.name || '', inputValue);
          const parts = parse(option?.name || '', matches);

          return (
            <li {...props}>
              <Typography data-testid={`fund-autocomplete-option-${option.isin}`} variant="body2">
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: 500,
                      color: part.highlight
                        ? theme.palette.text.primary
                        : theme.palette.text.secondary,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </Typography>
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t('components.isinSearch.placeholder')}
            variant="outlined"
            onChange={onInputChange}
            inputProps={{
              ...params.inputProps,
              'data-testid': 'fund-autocomplete-input',
            }}
          />
        )}
      />
      <StyledButton
        startIcon={<CheckmarkCircled />}
        color="secondary"
        onClick={addFund}
        variant="outlined"
        size="small"
        data-eventid="query-discount"
        sx={{
          flexShrink: 0,
        }}
      >
        {t('components.isinSearch.applyButton')}
      </StyledButton>
    </AutocompleteWrapper>
  );
};
