import { ThemeOptions } from '@mui/material';
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import keyBy from 'lodash/keyBy';

const generateStringFromProps = (props: Record<string, string>) => {
  return Object.values(props).join('-');
};

export const mergeStyles = (style1: ThemeOptions, style2: ThemeOptions): ThemeOptions => {
  return mergeWith(style1, style2, (val1, val2) => {
    if (Array.isArray(val1) && Array.isArray(val2)) {
      if (!val1.find((val) => 'props' in val)) return;

      const result = keyBy(val1, (val) => generateStringFromProps(val.props));
      val2.forEach((val) => {
        const key = generateStringFromProps(val.props);
        result[key] = merge(result[key], val);
      });
      return Object.values(result);
    }
  });
};
