import { useCallback } from 'react';
import { useRouter } from 'next/router';

export const useGoogleTagManager = (userFlowId: string) => {
  const router = useRouter();

  return useCallback(
    (data: Record<string, string | undefined | null>) => {
      window.dataLayer?.push({
        userFlowId,
        workflowId: userFlowId,
        pageRoute: router.pathname,
        ...data,
      });
    },
    [userFlowId]
  );
};
