import { useTranslation } from 'react-i18next';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import { rentabloThemeLightDe, rentabloThemeLightEn } from '../themes/rentabloTheme';

export const useLocalizedTheme = () => {
  const { i18n } = useTranslation();
  const isDeLocale = i18n.language === 'de';

  const theme = isDeLocale ? rentabloThemeLightDe : rentabloThemeLightEn;
  const dateLocale = isDeLocale ? deLocale : enLocale;

  return { theme, dateLocale };
};
