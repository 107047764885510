import axios from 'axios';
import { useQuery } from 'react-query';

const API_URL = 'https://rentablo.de/api/v1';

export interface FundList {
  funds: Fund[];
}

export interface Fund {
  id: number;
  type: string;
  isin: string;
  wkn?: string;
  name: string;
  emitter?: string;
  category?: string;
  subCategory?: string;
  fundCurrency?: string;
  riskClass?: number;
  popularity?: 1 | 2 | 3;
  courtages: Courtage[];
}

export interface Courtage {
  bankName: string;
  bic: string;
  agio: number;
  discountOnAgio: number;
  portfolioCommission: number;
  hasSavingsPlan?: boolean;
  savingsPlanMinAmount?: number;
  hasVl?: boolean;
}

const searchFunds = async (search: string) => {
  const response = await axios.get<FundList>(
    `${API_URL}/funds`,

    { params: { search, includeCommissions: true, perPage: 10 } }
  );

  return response.data;
};

export const useFunds = (search?: string) =>
  useQuery(['searchFunds', search!], () => searchFunds(search!), {
    enabled: !!search && search.length >= 3,
  });
