import React, { ReactNode } from 'react';
import Head from 'next/head';
import { isIE } from 'react-device-detect';
import { ThemeProvider } from '@mui/material';
import { IEAlert } from '../../IEAlert';
import { useLocalizedTheme } from '../../../plugins/material-ui/hooks/useLocalizedTheme';
import { CashbackCalculatorProvider } from '../../CashbackCalculatorProvider';
import { Header } from './Header';
import { Footer } from './Footer';

export const Layout = ({
  children,

  searchEnabled = false,
}: {
  children: ReactNode;

  searchEnabled?: boolean;
}) => {
  const { theme } = useLocalizedTheme();

  return (
    <ThemeProvider theme={theme}>
      {isIE && <IEAlert />}
      <Head>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <CashbackCalculatorProvider>
        <Header searchEnabled={searchEnabled} />
        <main>{children}</main>
      </CashbackCalculatorProvider>
      <Footer />
    </ThemeProvider>
  );
};
