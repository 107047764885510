import React from 'react';
import { Button, Menu, MenuItem, Typography, styled, Theme, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ImportContactsRoundedIcon from '@mui/icons-material/ImportContactsRounded';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import HeadsetMicRoundedIcon from '@mui/icons-material/HeadsetMicRounded';
import { useTranslation } from 'react-i18next';
import { BLOG, FORUM } from '../../../src/helpers/urlConstants';

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: 'flex',
  gap: theme.spacing(2),
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:hover > *': {
    color: theme.palette.text.primary,
  },
}));

export const useStyles = makeStyles((theme: Theme) => ({
  menuPaper: {
    backgroundColor: theme.palette.background.default,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
}));

export const LinkStyled = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  '&:hover': {
    textDecoration: 'none',
  },
}));

const NavigationButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(2, 4),
}));

export const HeaderKnowledgeSubmenu = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <NavigationButton size="small" onClick={handleClick}>
        <Typography color="textSecondary">
          {t('components.layouts.redesign.header.actions.knowledge')}
        </Typography>
      </NavigationButton>
      <Menu
        anchorEl={anchorEl}
        classes={{ paper: classes.menuPaper }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItemStyled onClick={handleClose}>
          <LinkStyled href={FORUM} color="inherit">
            <ForumRoundedIcon style={{ fontSize: '20px' }} />
            <Typography>{t('components.layouts.redesign.header.actions.forum')}</Typography>
          </LinkStyled>
        </MenuItemStyled>
        <MenuItemStyled onClick={handleClose}>
          <LinkStyled href={BLOG} color="inherit">
            <ImportContactsRoundedIcon style={{ fontSize: '20px' }} />
            <Typography>{t('components.layouts.redesign.header.actions.blog')}</Typography>
          </LinkStyled>
        </MenuItemStyled>
        <MenuItemStyled onClick={handleClose}>
          <LinkStyled href={`mailto:${t('customerService.email')}`} color="inherit">
            <HeadsetMicRoundedIcon style={{ fontSize: '20px' }} />
            <Typography>{t('components.layouts.redesign.header.actions.support')}</Typography>
          </LinkStyled>
        </MenuItemStyled>
      </Menu>
    </>
  );
};
