import { ReactNode, createContext, useRef, RefObject } from 'react';
import { CalculatorState } from '@rentablo/cashback-calculator';

export const CashbackCalculatorContext = createContext<RefObject<CalculatorState>>({
  current: null,
});

type CashbackCalculatorProviderProps = {
  children: ReactNode;
};

export const CashbackCalculatorProvider = ({ children }: CashbackCalculatorProviderProps) => {
  const ref = useRef<CalculatorState>({});

  return (
    <CashbackCalculatorContext.Provider value={ref}>{children}</CashbackCalculatorContext.Provider>
  );
};
