import {
  styled,
  Link,
  Typography,
  Container,
  Grid,
  Divider,
  useMediaQuery,
  Theme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';

const Wrapper = styled('div')(({ theme }) => ({
  background: theme.palette.grey[800],
  padding: theme.spacing(14, 0, 11),
}));

const Logo = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(4),
  width: '89px',
  height: '32px',
}));

const Copyright = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

const GridContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    order: 1,
  },
  [theme.breakpoints.only('xs')]: {
    gap: theme.spacing(4),
  },
}));

const FirstColumn = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    order: 1,
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  fontWeight: 600,
  marginBottom: theme.spacing(2),
}));

const LinkText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  marginBottom: theme.spacing(2),
}));

const SubLink = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  background: theme.palette.grey[700],
  margin: theme.spacing(11, 0, 11, 0),
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(6, 0, 11, 0),
  },
}));

const SubFooterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: theme.spacing(4),
}));

const RatingStyled = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(5),

  '& #pe_rating': {
    textAlign: 'left',
  },
  '& #pe_rating.pe_l>span>.pe_u': {
    display: 'block',
    marginTop: theme.spacing(1),
  },
}));

interface TranslationColumn {
  href: string;
  title: string;
}

export const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const isLessSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const column1 = t('components.layouts.redesign.footer.column1.items', {
    returnObjects: true,
  }) as TranslationColumn[];
  const column2 = t('components.layouts.redesign.footer.column2.items', {
    returnObjects: true,
  }) as TranslationColumn[];
  const column3 = t('components.layouts.redesign.footer.column3.items', {
    returnObjects: true,
  }) as TranslationColumn[];
  const subFooterLinks = t('components.layouts.redesign.footer.subFooterItems', {
    returnObjects: true,
  }) as TranslationColumn[];

  return (
    <Wrapper>
      <Container>
        <GridContainer container>
          <FirstColumn item xs={12} sm={5} md={3}>
            <Logo src="/images/rentablo-logo-white-new.svg" loading="lazy" alt={t('')} />
            <Copyright variant="body2">
              © {currentYear} <br />
              Eine Marke der wealthAPI GmbH
            </Copyright>
            <RatingStyled>
              <div className="pe-richsnippets" />
            </RatingStyled>
          </FirstColumn>
          <Grid item xs={12} sm={4} md={3}>
            <Title variant="body1">{t('components.layouts.redesign.footer.column1.title')}</Title>
            {column1.map((item) => (
              <Link href={item.href} key={item.title} underline="hover">
                <LinkText variant="body2">{item.title}</LinkText>
              </Link>
            ))}
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Title variant="body1">{t('components.layouts.redesign.footer.column2.title')}</Title>
            {column2.map((item) => (
              <Link href={item.href} key={item.title} underline="hover">
                <LinkText variant="body2">{item.title}</LinkText>
              </Link>
            ))}
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Title variant="body1">{t('components.layouts.redesign.footer.column3.title')}</Title>
            {column3.map((item) => (
              <Link href={item.href} key={item.title} underline="hover">
                <LinkText variant="body2">{item.title}</LinkText>
              </Link>
            ))}
          </Grid>
        </GridContainer>

        <DividerStyled />

        <SubFooterContainer>
          {subFooterLinks.map((item) => (
            <Link href={item.href} key={item.title} underline="hover">
              <SubLink variant={isLessSm ? 'caption' : 'body2'}>{item.title}</SubLink>
            </Link>
          ))}
        </SubFooterContainer>
      </Container>
    </Wrapper>
  );
};
