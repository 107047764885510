import { LOGIN_TOKEN_KEY } from '../../constants';
import { LoginTokenWithExpiry } from '../../api/getAccessTokenByRefreshToken';
import { isExpired } from './getAccessTokenQueryFn';

export const checkAuthState = (): boolean => {
  if (typeof window === 'undefined') return false;
  const tokenString = localStorage.getItem(LOGIN_TOKEN_KEY);
  if (!tokenString) return false;
  const token: LoginTokenWithExpiry = JSON.parse(tokenString);

  if (token.refresh_token) return true;
  if (token.access_token) return !isExpired(token);

  return false;
};

export const getLoginToken = (): LoginTokenWithExpiry | undefined => {
  const tokenString = localStorage.getItem(LOGIN_TOKEN_KEY);
  if (!tokenString) return undefined;

  return JSON.parse(tokenString);
};
