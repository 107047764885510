import { addMilliseconds, addSeconds, parseISO } from 'date-fns';
import { useQuery, UseQueryOptions } from 'react-query';
import {
  getAccessTokenByRefreshToken,
  LoginToken,
  LoginTokenWithExpiry,
} from '../../api/getAccessTokenByRefreshToken';
import { LOGIN_TOKEN_KEY } from '../../constants';
import { getLoginToken } from './tokenHelper';

export const addDate = (tokenWithoutDate: LoginToken) => {
  return {
    ...tokenWithoutDate,
    expires_at: addSeconds(new Date(), tokenWithoutDate.expires_in).toISOString(),
  };
};

export const getAccessTokenQueryFn = async () => {
  let token = getLoginToken();

  if (!token) {
    return Promise.resolve(undefined);
  }

  if (token && isExpired(token)) {
    const tokenWithoutDate = await getAccessTokenByRefreshToken({
      refreshToken: token.refresh_token,
    });

    token = addDate(tokenWithoutDate);
  }

  return Promise.resolve(token);
};

export const useGetAccessToken = (options: UseQueryOptions<LoginTokenWithExpiry | undefined>) =>
  useQuery<LoginTokenWithExpiry | undefined>(LOGIN_TOKEN_KEY, getAccessTokenQueryFn, options);

// request new token 5 seconds earlier expire time
const REFRESH_DELTA = 5000;

export const isExpired = (token: LoginTokenWithExpiry) => {
  return addMilliseconds(parseISO(token.expires_at), -REFRESH_DELTA) < new Date();
};
