import React, { ReactNode, useEffect, useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  styled,
  Toolbar,
  Typography,
  useScrollTrigger,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import {
  ABOUT_US,
  CASHBACK_WIZARD_START,
  FINANCE_MANAGER_DASHBOARD,
  HOME,
  LOGIN,
  YOUR_DISCOUNT,
} from '../../../src/helpers/urlConstants';
import { Link } from '../../Link';
import { ISINSearch } from '../../ISINSearch/ISINSearch';
import { checkAuthState } from '../../../src/helpers/tokenHelper';
import { HeaderMobileMenu } from './HeaderMobileMenu';
import { HeaderKnowledgeSubmenu } from './HeaderKnowledgeSubmenu';

const Logo = styled('img')(({ theme }) => ({
  width: '105px',
  height: '38px',

  [theme.breakpoints.down('md')]: {
    width: '88px',
    height: '32px',
  },
}));

interface ScrolledProps {
  isScrolled: boolean;
  children: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HeaderWrapper = styled(({ isScrolled, ...rest }: ScrolledProps) => <AppBar {...rest} />)(
  ({ theme, isScrolled }) => ({
    display: 'flex',
    padding: theme.spacing(2, 5),
    height: '65px',
    boxShadow: 'none',
    background: 'white',
    borderBottom: `1px solid ${theme.palette.border?.main}`,

    [theme.breakpoints.down('md')]: {
      height: '58px',
      padding: theme.spacing(1, 3),
      borderBottom: isScrolled ? `1px solid ${theme.palette.border?.main}` : 'none',
    },
  })
);

const ActionsWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',

  [theme.breakpoints.up('lg')]: {
    justifyContent: 'space-between',
  },
}));

const LoginButton = styled(Button)(({ theme }) => ({
  background: theme.palette.background.default,
  color: theme.palette.text.primary,
  marginRight: theme.spacing(3),
}));

const LogoLink = styled(Link)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    marginRight: theme.spacing(11),
  },
  [theme.breakpoints.down(1325)]: {
    marginRight: theme.spacing(3),
  },
}));

const ActionsContainer = styled('div')({
  display: 'flex',
  alignItems: 'inherit',
  flexShrink: 0,
});

const MainMenuActions = styled(ActionsContainer)(({ theme }) => ({
  gap: theme.spacing(3),
  marginRight: theme.spacing(9),
  flexShrink: 0,

  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },

  [theme.breakpoints.down(1325)]: {
    gap: theme.spacing(0),
    marginRight: theme.spacing(3),
  },
}));

const ButtonsBlock = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

const NavigationButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(2, 4),
}));

const AuthenticationButton = ({ isAuthenticated }: { isAuthenticated: boolean | null }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  if (isAuthenticated == null) {
    return null;
  }

  return !isAuthenticated ? (
    <LoginButton href={LOGIN} variant="outlined" color="grey" size="small" data-eventid="register">
      {t('components.layouts.redesign.header.actions.login')}
    </LoginButton>
  ) : (
    <Button
      href={FINANCE_MANAGER_DASHBOARD}
      variant="contained"
      color="tertiary"
      size="small"
      data-eventid="to-app"
      sx={{
        marginRight: theme.spacing(3),
      }}
    >
      {t('components.layouts.redesign.header.actions.inApp')}
    </Button>
  );
};

export function Header({ searchEnabled }: { searchEnabled: boolean }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
  });
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const router = useRouter();

  useEffect(() => {
    setIsAuthenticated(checkAuthState());
  }, []);

  const navigateToDiscount = () => {
    const discountSection = document.querySelector(YOUR_DISCOUNT);

    if (discountSection) {
      discountSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      router.push(`/${YOUR_DISCOUNT}`);
    }
  };

  const scrollTo = (selector: string) => {
    const el = document.querySelector(selector);
    el?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <HeaderWrapper isScrolled={trigger} data-sectionid="cashback-lp-header">
      <Toolbar variant="dense" disableGutters>
        <LogoLink href={HOME}>
          <Logo src="/images/rentablo-logo.svg" loading="lazy" />
        </LogoLink>
        <ActionsWrapper>
          <MainMenuActions>
            <NavigationButton onClick={navigateToDiscount} size="small">
              <Typography color="textSecondary">
                {t('components.layouts.redesign.header.actions.yourDiscount')}
              </Typography>
            </NavigationButton>
            <NavigationButton onClick={() => scrollTo('#conditions')} size="small">
              <Typography color="textSecondary">
                {t('components.layouts.redesign.header.actions.conditions')}
              </Typography>
            </NavigationButton>
            <HeaderKnowledgeSubmenu />
            <NavigationButton href={ABOUT_US} size="small">
              <Typography color="textSecondary">
                {t('components.layouts.redesign.header.actions.aboutUs')}
              </Typography>
            </NavigationButton>
          </MainMenuActions>

          <ActionsContainer>
            {searchEnabled && (
              <Box
                sx={{
                  display: 'none',
                  marginRight: theme.spacing(9),
                  [theme.breakpoints.down(1325)]: {
                    marginRight: theme.spacing(3),
                  },
                  [theme.breakpoints.down('lg')]: {
                    marginRight: theme.spacing(9),
                  },
                  [theme.breakpoints.up('md')]: {
                    display: 'block',
                  },
                }}
              >
                <ISINSearch />
              </Box>
            )}

            <ButtonsBlock>
              <AuthenticationButton isAuthenticated={isAuthenticated} />
              <Button
                color="primary"
                variant="contained"
                href={CASHBACK_WIZARD_START}
                size="small"
                data-eventid="book-cashback-button"
              >
                {t('components.layouts.redesign.header.actions.cashback')}
              </Button>
            </ButtonsBlock>

            <HeaderMobileMenu />
          </ActionsContainer>
        </ActionsWrapper>
      </Toolbar>
    </HeaderWrapper>
  );
}
