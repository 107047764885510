import { ThemeOptions, TypeText, TypeBackground, alpha } from '@mui/material';
import { deDE, enUS } from '@mui/material/locale';
import { PaletteOptions, createTheme } from '@mui/material/styles';
import {
  ColorPartial,
  CommonColors,
  SimplePaletteColorOptions,
} from '@mui/material/styles/createPalette';
import { Shadows } from '@mui/material/styles/shadows';
import { mergeStyles } from '../mergeStyles';
import { baseLightColors, baseLightTheme } from './baseTheme';

const theme = createTheme({ spacing: 4 });

export const shadows: Shadows = [
  'none',
  '0px 1px 2px rgba(30, 39, 50, 0.16)',
  '0px 2px 4px -1px rgba(30, 39, 50, 0.16)',
  '0px 4px 6px -1px rgba(30, 39, 50, 0.16)',
  '0px 4px 12px -1px rgba(30, 39, 50, 0.12), 0px 4px 16px -2px rgba(30, 39, 50, 0.16)',
  '0px 8px 16px -2px rgba(30, 39, 50, 0.12), 0px 6px 24px -2px rgba(30, 39, 50, 0.16)',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
];

const colors: PaletteOptions & {
  text: Partial<TypeText>;
  background: Partial<TypeBackground>;
  primary: SimplePaletteColorOptions;
  common: Partial<CommonColors>;
  grey: ColorPartial;
} = {
  common: {
    ...baseLightColors,
  },
  text: {
    primary: baseLightColors.N800,
    secondary: baseLightColors.N600,
    disabled: baseLightColors.N300,
    positive: baseLightColors.G500,
    negative: baseLightColors.R500,
  },
  background: {
    default: baseLightColors.N000,
    paper: baseLightColors.N000,
    neutralLight: baseLightColors.N050,
    neutral: baseLightColors.N100,
    primary: baseLightColors.O100,
    secondary: baseLightColors.O100,
    tertiary: baseLightColors.M100,
    error: baseLightColors.R100,
    warning: baseLightColors.Y100,
    info: baseLightColors.B100,
    success: baseLightColors.G100,
  },
  chart: {
    primary: baseLightColors.M400,
    dark: baseLightColors.N800,
  },
  primary: {
    lighter: baseLightColors.O200,
    light: baseLightColors.O300,
    main: baseLightColors.O400,
    dark: baseLightColors.O500,
    contrastText: baseLightColors.N000,
  },
  secondary: {
    lighter: baseLightColors.O200,
    light: baseLightColors.O300,
    main: baseLightColors.O400,
    dark: baseLightColors.O500,
    contrastText: baseLightColors.N000,
  },
  tertiary: {
    lighter: baseLightColors.M200,
    light: baseLightColors.M300,
    main: baseLightColors.M400,
    dark: baseLightColors.M500,
    contrastText: baseLightColors.N000,
  },
  error: {
    lighter: baseLightColors.R200,
    light: baseLightColors.R300,
    main: baseLightColors.R400,
    dark: baseLightColors.R500,
    contrastText: baseLightColors.N000,
  },
  warning: {
    lighter: baseLightColors.Y200,
    light: baseLightColors.Y300,
    main: baseLightColors.Y400,
    dark: baseLightColors.Y500,
    contrastText: baseLightColors.N000,
  },
  info: {
    lighter: baseLightColors.B200,
    light: baseLightColors.B300,
    main: baseLightColors.B400,
    dark: baseLightColors.B500,
  },
  success: {
    lighter: baseLightColors.G200,
    light: baseLightColors.G300,
    main: baseLightColors.G400,
    dark: baseLightColors.G500,
    contrastText: baseLightColors.N000,
  },
  icon: {
    light: baseLightColors.N200,
    main: baseLightColors.N600,
    active: baseLightColors.M400,
  },
  border: {
    light: baseLightColors.N100,
    main: baseLightColors.N200,
    dark: baseLightColors.N300,
    active: baseLightColors.M400,
  },
  grey: {
    main: baseLightColors.N300,
    dark: baseLightColors.N400,
    50: baseLightColors.N050,
    100: baseLightColors.N100,
    200: baseLightColors.N200,
    300: baseLightColors.N300,
    400: baseLightColors.N400,
    500: baseLightColors.N500,
    600: baseLightColors.N600,
    700: baseLightColors.N700,
    800: baseLightColors.N800,
    900: baseLightColors.N900,
  },
};
const rentabloTheme: ThemeOptions = {
  typography: {
    fontSize: 16, // this is the global default
    // "BiryaniLatin-Regular" is name used in main angular application.
    // It has to be here until we stop using embedded components
    fontFamily: ['Inter', 'Biryani', 'BiryaniLatin-Regular', '"Zilla Slab"', 'sans-serif'].join(
      ','
    ),
    body1: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: '-0.01em',
    },
    body2: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 1.43,
      letterSpacing: '-0.01em',
    },
    h1: {
      fontSize: '44px',
      fontWeight: 700,
      lineHeight: '54px',

      '@media (max-width:601px)': {
        fontSize: '32px',
        fontWeight: 700,
        lineHeight: '40px',
      },
      '@media (max-width:400px)': {
        fontSize: '30px',
        fontWeight: 700,
        lineHeight: '40px',
      },
    },
    h2: {
      fontSize: '38px',
      fontWeight: 700,
      lineHeight: '48px',
      letterSpacing: '-0.02em',
      '@media (max-width:600px)': {
        fontSize: '28px',
        fontWeight: 800,
        lineHeight: '34px',
      },
    },
    h3: {
      fontSize: '32px',
      fontWeight: 700,
      lineHeight: '40px',

      '@media (max-width:601px)': {
        fontSize: '26px',
        fontWeight: 700,
        lineHeight: '34px',
      },
    },
    h4: {
      fontSize: '28px',
      fontWeight: 600,
      lineHeight: '36px',

      '@media (max-width:601px)': {
        fontSize: '24px',
        fontWeight: 500,
        lineHeight: '34px',
      },
    },
    h5: {
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '34px',
      '@media (max-width:601px)': {
        fontSize: '18px',
        lineHeight: '24px',
      },
    },
    h6: {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '28px',
      letterSpacing: '-0.02em',
    },
    subtitle1: {
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '30px',
      letterSpacing: '0.75px',
    },
    subtitle2: {
      color: baseLightColors.N600,
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 500,
      letterSpacing: '-0.01em',

      '@media (max-width:601px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    subtitle2bold: {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 600,
      letterSpacing: '-0.01em',

      '@media (max-width:601px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    subtitle3: {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '36px',
    },
    textLight: {
      fontFamily: 'Inter, Biryani, BiryaniLatin-Regular, Zilla Slab',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
    },
    text: {
      fontFamily: 'Inter, Biryani, BiryaniLatin-Regular, Zilla Slab',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
    },
    textBold: {
      fontFamily: 'Inter, Biryani, BiryaniLatin-Regular, Zilla Slab',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 600,
    },
    button: {
      fontWeight: 700,
    },
    overline: {
      fontSize: '14px',
      fontWeight: 800,
      letterSpacing: '2px',
    },
    caption: {
      fontSize: '12px',
    },
  },
  palette: colors,
  shape: {
    borderRadius: 6,
  },
  shadows: shadows,
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          padding: '10px 16px',
          textTransform: 'none',
          fontWeight: 500,
        },
        startIcon: {
          marginRight: '8px',
        },
        endIcon: {
          marginLeft: '8px',
        },
      },
      variants: [
        {
          props: { color: 'grey' },
          style: {
            '&:not(.Mui-disabled) .MuiButton-startIcon': {
              color: colors.text.secondary,
            },

            '&:not(.Mui-disabled) .MuiButton-endIcon': {
              color: colors.text.secondary,
            },
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            backgroundColor: colors.background.neutral,
            color: colors.text.secondary,
            borderRadius: 6,
            padding: '10px 16px',
            lineHeight: 1.75,

            '&.Mui-disabled': {
              backgroundColor: colors.background.neutral,
              color: colors.common.N000,
              borderRadius: 6,
              borderColor: colors.border?.light,
            },

            '&:hover': {
              backgroundColor: colors.grey[300],
            },
          },
        },
        {
          props: { variant: 'contained', size: 'small' },
          style: {
            padding: '7px 12px',
            fontSize: '14px',
          },
        },
        {
          props: { variant: 'contained', size: 'large' },
          style: {
            padding: '7px 21px',
            fontSize: '16px',
            lineHeight: '24px',
          },
        },
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            backgroundColor: colors.primary.main,
            color: colors.common.N000,
            borderRadius: 6,

            '& .MuiButton-startIcon': {
              color: colors.common.N000,
            },

            '&:hover': {
              backgroundColor: colors.primary.dark,
            },
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            color: colors.primary.main,

            ':hover': {
              backgroundColor: alpha(colors.primary.main, 0.04),
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            color: colors.primary.dark,
            backgroundColor: colors.background.primary,
            borderColor: colors.primary.lighter,

            '&.Mui-disabled': {
              color: colors.grey[300],
              borderColor: colors.border?.light,
            },

            '&:hover': {
              backgroundColor: colors.primary.lighter,
              borderColor: colors.primary.main,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            backgroundColor: colors.background.primary,
            color: colors.primary.main,
            borderWidth: 1,
            borderColor: colors.primary.lighter,
            borderStyle: 'solid',
            borderRadius: 6,
            padding: '9px 16px',

            '&.Mui-disabled': {
              backgroundColor: colors.background.neutralLight,
              color: colors.grey[300],
            },

            '&:hover': {
              backgroundColor: colors.primary.lighter,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'tertiary' },
          style: {
            backgroundColor: colors.tertiary.main,
            color: colors.primary.contrastText,
            borderRadius: 6,
            padding: '9px 16px',

            '&.Mui-disabled': {
              backgroundColor: colors.background.neutralLight,
              color: colors.grey[300],
            },

            '&:hover': {
              backgroundColor: colors.tertiary.dark,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'tertiary', size: 'small' },
          style: {
            padding: '6px 12px',
          },
        },
        {
          props: { variant: 'contained', color: 'secondary', size: 'small' },
          style: {
            padding: '6px 12px',
          },
        },
        {
          props: { variant: 'contained', color: 'grey' },
          style: {
            color: colors.text.secondary,
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            borderRadius: 6,
            lineHeight: '24px',
            padding: '9px 15px',

            '&:disabled': {
              color: colors.grey[300],
              boxShadow: 'none !important',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'grey' },
          style: {
            // Note: we must use important rule because we define default props MuiButton disableElevation: true
            boxShadow: `${shadows[1]} !important`,
            color: colors.text.primary,
            borderColor: colors.border?.main,

            '&:hover': {
              backgroundColor: colors.background.neutralLight,
            },
          },
        },
        {
          props: { variant: 'outlined', size: 'small' },
          style: {
            padding: '6px 11px',
            fontSize: '14px',
            lineHeight: '24px',
          },
        },
        {
          props: { variant: 'outlined', size: 'large' },
          style: {
            padding: '11px 15px',
            fontSize: '1.0714285714285714rem',
          },
        },
        {
          props: { variant: 'text' },
          style: {
            padding: '10px 16px',
            lineHeight: '24px',
          },
        },
        {
          props: { variant: 'text', color: 'grey' },
          style: {
            color: colors.text.primary,

            '&:hover': {
              backgroundColor: colors.background.neutralLight,
            },
          },
        },
        {
          props: { variant: 'text', size: 'small' },
          style: {
            padding: '7px 12px',
            fontSize: '14px',
            lineHeight: '22px',
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          height: '44px',
          width: '44px',
          color: colors.text.secondary,

          '& .MuiTouchRipple-ripple': {
            borderRadius: 'inherit',
          },

          '& .MuiTouchRipple-child': {
            borderRadius: 'inherit !important',
          },
        },
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            width: '36px',
            height: '36px',
          },
        },
        { props: { color: 'primary' }, style: { color: colors.primary.main } },
        {
          props: { color: 'secondary' },
          style: { color: colors.background.primary },
        },
      ],
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h4',
          h6: 'h4',
          subtitle1: 'p',
          subtitle2: 'p',
          subtitle3: 'p',
          body1: 'p',
          body2: 'p',
          text: 'p',
          textLight: 'p',
          textBold: 'p',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '1280px',
          [theme.breakpoints.down('md')]: {
            padding: `0 ${theme.spacing(3)}`,
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          '&:hover': { backgroundColor: baseLightColors.ink050 },
          backgroundColor: baseLightColors.ink100,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          '&.Mui-focused': {
            backgroundColor: baseLightColors.ink100,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: baseLightColors.N000,
          backgroundColor: baseLightColors.N900,
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: '22px',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          color: baseLightColors.M400,

          '&:hover': {
            textDecoration: 'none',
            color: baseLightColors.M500,
          },
        },
      },
    },
  },
};

const mergedTheme = mergeStyles(rentabloTheme, baseLightTheme);

export const rentabloThemeLightEn = createTheme(mergedTheme, enUS);
export const rentabloThemeLightDe = createTheme(mergedTheme, deDE);
